@import "../../_vars";

#Background {
  background-image: url("../../images/blue.jpg");
  @include __bgImgVec;
  background-attachment: fixed;
  background-size: 120vw 150vh;
  background-position: 0 0;
  background-repeat: no-repeat;
  .background {
    @include __bgDimmer;
    align-items: flex-start;
    padding-top: 0;
    padding-bottom: 0;
    background-color: rgba($color: #ededed, $alpha: 0.9);
    .container {
      height: 100%;
      overflow: hidden;
      .MuiTab-root{
        outline: none;
      }
      .MuiTab-textColorPrimary{
        color: #2b2b2b;
      }
      .tab-panel{
        width: 100%;
      }
      .caard {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 32px 32px 32px 32px;
      }
      .degree, .role{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 7px;
      }
      .logo {
        display: inline-block;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        margin-right: 7px;
      }
      .more {
        font-family: "Nunito", sans-serif;
        font-size: 18px;
        width: 100%;
      }
      .date,
      .location {
        svg {
          color: #2b2b2b;
        }
        span {
          margin-left: 10px;
          color: #2b2b2b;
          font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
            monospace;
        }
      }
      .description {
        font-size: 17px;
      }
    }
  }
  @media only screen and (max-width: $xl) {
    background-attachment: fixed;
    height: fit-content;
    .background{
      .container{
        width: 100%;
        height: fit-content !important;
        min-height: 100vh;
        padding: 0;
        overflow: visible !important;
        .tab-title{
          display: none;
        }
        .MuiBox-root{
          padding: 0;
        }
        .card{
          padding: 10px 50px;
          .description, .relevants {
            font-size: 15px;
          }
        }
      }
    }
  }
}
