$hw: 230px;
$xs: 310px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

@mixin __bgImg{
  height: auto;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
}

@mixin __bgImgVec{
  height: auto;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
  background-position: center center;
}

@mixin __bgDimmer{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: rgba($color: #2b2b2b, $alpha: 0.5);
  height: auto;
  min-height: 100vh;
  // width: 100%;
  margin-left: $hw;
  padding: 25px 5px 25px 10px;
  @media only screen and (max-width: $xl) {
    padding: 15px 5px 15px 5px;
    margin-left: 0;
  }
}