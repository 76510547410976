@import './__vars.scss';
#root{
  background-color: #ededed;
}

.App-header {
  box-sizing: border-box;
  display: block;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: $hw;
  height: 100vh;
  @media only screen and (max-width: $xl) {
    width: 0;
  }
}
.App-main {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  height: auto;
  margin-left: auto;
  section{
    box-shadow: 0 1px 5px 1px rgba($color: #3b3b3b, $alpha: 0.5);
    min-height: 100vh;
    margin-bottom: 40px;
  }
}
