@import "../../_vars";

$default: rgba(0, 0, 0, 0.87);
$primary: #1b7aa0;
$secondary: #99346f;

#Projects {
  background-image: url("../../images/white.png");
  @include __bgImgVec;
  background-attachment: fixed;
  background-size: 25% 100%;
  background-position: left bottom;
  .background {
    @include __bgDimmer;
    align-items: flex-start;
    background-color: rgba($color: #000000, $alpha: 0.01);
    padding-left: 85px;
    min-height: fit-content;
    .rep{
      margin-bottom: 25px;
      .top{
        padding: 15px;
        border-radius: 5px;
        background-color: rgba($color: #fff, $alpha: 0.25);
        backdrop-filter: blur(2px);
        margin-bottom: 25px;
      }
      .items{
        .item{
          margin-bottom: 25px;
          .name{
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            div{
              margin: 0 10px;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: $xl) {
    .background{
      .rep{
        .items{
          padding: 0 15px;
        }
      }
    }
  }
}
