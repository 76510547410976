@import "../__vars.scss";

.navbar-wrapper{
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #2b2b2b, $alpha: 0.1);
  border-radius: 10px/5px 200px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  overflow: hidden;
  box-shadow: 2px 0 7px 2px rgba($color: #000000, $alpha: 0.1), 3px 0 15px 1px rgba(51, 51, 51, 0.39);
  .navbar-vertical {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: transparent;
    backdrop-filter: blur(2px);
    .nav-item{
      margin-bottom: 20px;
      width: 190px;
      .nav-link{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        padding: 5px 0;
        padding-left: 20px;
        padding-right: 20px;
        background-color: transparent;
        transition: border-radius 300ms ease-in-out;
        .nav-item-icon {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          padding-right: 10px;
          overflow: hidden;
          i{
            position: static;
          }
        }
      }
    }
    .navbarCurrent .nav-link{
      background-color: rgba($color: #1a1a1a, $alpha: 0.95);
      border-radius: 10% 40% / 50% 15%;
      box-shadow: 0 0 4px 1px #ededed, 0 0 4px 1px #3b3b3b;
      color: #4be07d;
      font-weight: 900;
    }
  }
  @media only screen and (max-width: $xl) {
    display: none;
  }
}