@import '../../_vars';

#Home{
  .bg{
    background: url('../../images/background.jpg');
    @include __bgImg;
    min-height: 100vh;
    .background{
      @include __bgDimmer;
      background-color: rgba(11, 11, 11, 0.356);
    }
    .particles{
      position: absolute;
      background-color: transparent;
    }
    .details{
      position: absolute;
      top: 20%;
      right: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .prof-avatar{
        border-radius: 7px;
        border: 3px outset #000;
        position: relative;
        bottom: 0px;
        width: auto;
        height: 250px;
      }
    }
    .thereforiam{
      position: absolute;
      top: 25%;
      left: 15%;
      h3{
        font-size: 3rem;
        margin-bottom: 17px;
        div{
          height: 60px;
        }
      }
      p{
        color: #ededed;
        width: 300px;
      }
    }
    .lastupdate{
      position: absolute;
      bottom: 5px;
      margin-left: 15px;
    }
    @media only screen and (max-width: $xl) {
      background-position: left bottom;
      // background-size: auto 100vh;
      .details{
        position: relative;
        top: 0%;
        left: 0%;
        transform: translate(0%, 0%);
        width: 100%;
        padding: 25px;
        text-align: center;
        .prof-avatar{
          // display: none;
          width: 200px;
          height: 200px;
        }
      }
      .thereforiam{
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -25%);
      }
    }
  }
  .mainbg{
    position: relative;
    background: url('../../images/photo_2024-01-14_07-53-04.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    img{
      left: 20%;
      top: 5%;
    }
    .name{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(0%, -50%);
      font-weight: 900;
      font-size: 4.5rem;
      color: white !important;
      -webkit-text-stroke: 1px !important;
      -webkit-text-stroke-color: black !important;
    } 
    @media only screen and (max-width: $xl) {
      .dim{
        @include __bgDimmer;
      }
      img{
        left: 10%;
        top: 5%;
      }
      .name{
        top: 90%;
        width: 300px;
        font-size: 2.5rem;
        transform: translate(-50%, -50%);
        text-align: center;
      }
    }
  }
}