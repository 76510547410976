@import "../../_vars";

#Footer {
  background-image: url("../../images/nature.jpg");
  @include __bgImgVec;
  background-attachment: fixed;
  background-size: cover;
  background-position: center top;
  .background {
    @include __bgDimmer;
    align-items: flex-start;
    background-color: rgba($color: #000000, $alpha: 0.01);
    padding-left: 85px;
  }
  @media only screen and (max-width: $xl) {
    .background{
      padding: 35px 25px 15px 25px;
    }
  }
}
