@import "../../_vars";

#About {
  background-image: url("../../images/black.png");
  @include __bgImgVec;
  background-attachment: local;
  background-size: 50% 125%;
  background-position: 78vw center;
  .background {
    @include __bgDimmer;
    background-color: rgba($color: #000000, $alpha: 0.01);
    hr {
      margin: 10px 0 15px 0;
    }
    .top-col{
      margin-left: 50px;
      padding: 10px;
    }
    .contact {
      .badge-pill {
        display: inline-flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }
    }
    .description {
      font-family: "Nunito", sans-serif;
      font-size: 17px;
    }
  }
  @media only screen and (max-width: $xl) {
    background-image: none;
    .background{
      .top-col{
        margin-left: 0;
        padding: 10px 25px;
      }
    }
  }
}
