@import "../../_vars";

$default: rgba(0, 0, 0, 0.87);
$primary: #1b7aa0;
$secondary: #99346f;

#Skills {
  background-image: url("../../images/yellow.png");
  @include __bgImgVec;
  background-attachment: fixed;
  background-size: 27% 100%;
  background-position: left bottom;
  .background {
    @include __bgDimmer;
    align-items: flex-start;
    background-color: rgba($color: #000000, $alpha: 0.01);
    padding-left: 85px;
    min-height: fit-content;
    .help {
      margin-bottom: 25px;
      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        & > div {
          display: inline-block;
          width: 25px;
          height: 25px;
          margin-right: 15px;
          border-radius: 100%;
        }
        .h-default {
          background-color: $default;
        }
        .h-primary {
          background-color: $primary;
        }
        .h-secondary {
          background-color: $secondary;
        }
      }
    }
    .rep {
      width: 100%;
      padding-right: 15px;
      margin-bottom: 50px;
      .top {
        img {
          display: inline-block;
          width: 200px;
          height: auto;
          margin-right: 15px;
        }
        div {
          display: inline-block;
          font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
            monospace;
        }
        &.inv {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          img {
            margin-right: 0;
            margin-left: 15px;
          }
        }
      }
      .items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 15px;
        .item {
          margin: 5px 2.5px;
          .MuiChip-outlinedPrimary {
            color: $primary;
            border-color: $primary;
          }
          .MuiChip-outlinedSecondary {
            color: $secondary;
            border-color: $secondary;
          }
          .MuiChip-label {
            font-family: "Nunito", sans-serif;
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
    }
    .exams {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 25px;
      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
        img {
          display: inline-block;
          width: 100px;
          height: auto;
        }
        & > div {
          display: inline-block;
          margin: 0;
          margin-left: 15px;
        }
      }
    }
  }
  @media only screen and (max-width: $xl) {
    background-image: none;
    .background {
      padding: 15px 15px 15px 15px;
      .rep{
        .top{
          img{
            width: 150px;
            margin-right: 5px;
          }
          &.inv{
            img{
              margin-left: 5px;
            }
          }
        }
      }
      .exams {
        & > div {
          flex-direction: column;
          img {
            display: inline-block;
            width: 100px;
            height: auto;
          }
          & > div {
            display: inline-block;
            margin: 0;
            margin-left: 15px;
          }
        }
      }
    }
  }
}
